<template>
    <div class="auth--view">
        <div class="auth--container parent__container">
            <div class="auth--loader" v-if="isLoading">
                <video-loader></video-loader>
            </div>
            <div class="prepare--text-container">
                <p>Продолжая вы соглашаетесь с  <a @click="goToTerms">условиями использования сервиса и политикой конфиденциальности</a></p>
            </div>
            <div class="form--container">
                <div class="form">
                    <h1>Введите идентификатор</h1>
                    <div class="wrong--container" v-if="isWrongData">
                        <alert-text value="Ошибка! Неправильный логин или пароль"></alert-text>
                    </div>
                  <div class="wrong--container" v-if="isWrongData1">
                    <p class="warning__text">Вход возможен только</br>через мобильное приложение</p>
                  </div>

                    <div class="input--container">
                        <material-input id="login" type="text" label="ЛОГИН/ID" icon-class="icon-user" v-model="login"></material-input>
                    </div>
                    <div class="input--container">
                        <material-input id="password" type="password" label="ПАРОЛЬ" icon-class="icon-hide" v-model="password"></material-input>
                    </div>
                    <div class="button--container">
                        <material-button color="pink-gradient" size="md" value="ВОЙТИ" @click.native="auth"></material-button>

                    </div>
                </div>
            </div>
            <div class="support--button-container">
            </div>
            <div class="support--container">
                <div class="tip--container">
                    <div class="info--container">
                        <div class="avatar--container">
                            <img class="avatar" src="../assets/img/ava.374665b0.svg" draggable="false">
                        </div>
                        <div class="info-text--container">
                            <p>Анна</p>
                            <p>Специалист</p>
                        </div>
                    </div>
                    <div class="chat--list-container">
                        <div class="chat--list-item">
                            <p>Если вы новый пользователь: введите ID выданный учреждением.</p>
                        </div>
                        <div class="chat--list-item">
                            <p>Если вы забыли пароль напишите нам в чат!</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MaterialInput from "@/components/input/MaterialInput";
    import MaterialButton from "@/components/button/MaterialButton";
    import {AccountGateway} from "@/gateway/AccountGateway";
    import {AuthRequest} from "@/entity/requests/AuthRequest";
    import {WrongLoginOrPasswordException} from "@/entity/exception/WrongLoginOrPasswordException";
    import IMask from "imask";
    import AlertText from "@/components/alert/AlertText";
    import Loader from "@/components/loader/loader";
    import VideoLoader from "@/components/loader/VideoLoader";
    export default {
        name: "AuthTest",
        components: {VideoLoader, Loader, AlertText, MaterialButton, MaterialInput},
        data(){
            return {
                login : "",
                password : "",
                adminLogin: "",
                adminPassword: "",
                accountGateway: new AccountGateway(),
                isWrongData: false,
              isWrongData1: false,
                isLoading: false
            }
        },
        computed:{
            isMobile(){
                return screen.width < 992
            }
        },
        methods: {
          goToTerms(){
            window.open('https://esova.ru/terms_of_use',"_blank");
          },

            toAdmin(){
                this.login =  ""
                this.password = ""
                this.adminLogin= ""
                this.adminPassword= ""
                this.isWrongData = false
                this.isAdmin = true
            },

            toParent(){
                this.login =  ""
                this.password = ""
                this.adminLogin= ""
                this.adminPassword= ""
                this.isWrongData = false
                this.isAdmin = false
            },

            toggleTip(){
                if (this.isMobile){
                    this.isTipShow = !this.isTipShow
                }
            },

            enableTip(){
                if (!this.isMobile){
                    this.isTipShow = true
                }
            },

            disableTip(){
                if (!this.isMobile){
                    this.isTipShow = false
                }
            },


            async auth(){
                try{
                  this.isWrongData1 = false
                    this.isLoading = true
                    const auth = await this.accountGateway.auth(new AuthRequest(this.login,this.password));
                    setTimeout(async () => {
                        // localStorage.removeItem("accessToken");
                        // localStorage.removeItem("refreshToken");

                        localStorage.setItem("accessToken",auth.accessToken);
                        localStorage.setItem("refreshToken",auth.refreshToken);
                        try{
                          await this.$store.dispatch("GET_PROFILE")
                          this.isLoading = false
                          if (this.$store.getters.PROFILE.type === 1){
                            this.isWrongData1 = true
                            localStorage.removeItem("accessToken")
                            localStorage.removeItem("refreshToken")
                          } else {
                            this.$router.push({name: "ParentDashboard"})
                          }
                        } catch (e) {
                            this.isLoading = false
                            this.$router.push({name: "InterviewView"})
                        }
                    }, 1000)
                    this.isWrongData = false;

                } catch (e) {
                    this.isLoading = false
                    if (e instanceof WrongLoginOrPasswordException){
                        this.isWrongData = true;
                    } else {
                        console.log(e)
                        alert(e.stack)
                    }
                }
            },
        },
        mounted(){
        },

        created(){
            window.addEventListener('keyup', (event) => {
                if (event.keyCode == 13) {
                  this.auth()
                }
            });
        }
    }
</script>

<style scoped lang="scss">
    .auth--view{
        width: 100%;
        height: 100%;
        background-color: $bg-color;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-mobile-devices{
        }

        .auth--container{
            background: #FFFFFF;
            border: 0 solid #F2F2F2;
            box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
            display: flex;
            transition: height 0.3s;
            position: relative;


            @include for-mobile-devices{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                box-shadow: none;
                background: transparent;
            }

            .form--container{
                background-color: white;

                @include for-mobile-devices{
                    box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
                }

                .form{
                    margin-left: 48px;
                    margin-top: 38px;

                    @include for-mobile-devices{
                        margin-top: 34px;
                        margin-left: 35px;

                    }
                    h1{
                        font-family: Roboto, "sans-serif";
                        font-size: 24px;
                        color: #000000;
                        margin-bottom: 5px;
                    }

                    .wrong--container{
                        margin-top: 15px;
                    }

                    .input--container{
                        margin-top: 25px;
                    }

                    .button--container{
                        margin-top: 40px;
                        margin-bottom: 40px;

                        @include for-mobile-devices{
                            margin-top: 30px;
                            display: flex;
                            align-items: center;
                        }

                        a{
                            margin-left: 15px;

                            @include for-desktop-devices{
                                display: none;
                                margin-left: 0;
                            }
                        }
                    }


                }
            }

            .support--container{

                @include for-mobile-devices{
                    margin-top: 13px;
                    box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);

                }

                .change--container{
                    width: 100%;
                    height: 100%;
                    background-color: #F54E5E;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .tip--container{
                    width: 100%;
                    height: 100%;
                    background-color: #F5F5F5;
                    display: flex;
                    flex-direction: column;

                    .info--container{
                        display: flex;
                        margin-top: 23px;
                        margin-left: 20px;

                        @include for-mobile-devices{
                            display: none;
                        }

                        .avatar--container{
                            .avatar__img{
                                width: 60px;
                                height: 60px;
                                overflow: hidden;
                                border-radius: 50%;
                            }
                        }

                        .info-text--container{
                            margin-left: 17px;
                            margin-top: 2px;

                            p{
                                font-family: Roboto, "sans-serif";
                                font-size: 14px;
                                letter-spacing: 0;
                                pointer-events: none;
                            }

                            p:last-child{
                                margin-top: 7px;
                                font-family: Roboto, "sans-serif";
                                font-size: 11px;
                                color: #9B9B9B;
                                letter-spacing: 0;
                                pointer-events: none;
                            }
                        }
                    }

                    .chat--list-container{
                        margin-top: 18px;
                        margin-left: 10px;
                        margin-right: 10px;


                        .chat--list-item{
                            padding-top: 11px;
                            padding-bottom: 14px;
                            background: #EBEBEB;
                            border-radius: 4px;
                            margin-top: 10px;
                            @include for-mobile-devices{
                                margin-left: 34px;
                                margin-right: 56px;
                            }
                            p{
                                margin-left: 20px;
                                width: 158px;
                                font-family: Roboto, "sans-serif";
                                font-size: 12px;
                                font-weight: 300;
                                letter-spacing: 0;
                                pointer-events: none;
                                line-height: 15px;
                            }
                        }
                    }
                }

                .text--container{
                    margin-top: 89px;

                    @include for-mobile-devices{
                        margin-top: 24px;
                    }

                    p{
                        text-align: center;
                        width: 243px;
                        height: 88px;
                        font-size: 16px;
                        font-family: Roboto, "sans-serif";
                        font-weight: 300;
                        color: white;
                        line-height: 21px;

                        @include for-mobile-devices{
                            height: 68px;
                        }
                    }
                }

                .button--container{
                    margin-top: 62px;

                    @include for-mobile-devices{
                        margin-top: 20px;
                    }
                }
            }

            &.parent__container{
                width: 700px;
                height: auto;

                @include for-mobile-devices{
                    width: 350px;
                    height: auto;
                }


                .form--container{
                    width: 330px;

                    @include for-mobile-devices{
                        width: 350px;
                        height: auto;
                    }

                    height: auto;
                }

                .support--button-container{
                    width: 90px;
                    display: flex;
                    align-items: center;
                    a{
                        margin-left: 39px;
                    }

                    @include for-mobile-devices{
                        display: none;
                    }
                }

                .support--container{
                    width: 280px;

                    @include for-mobile-devices{
                        width: 350px;
                        height: 200px;
                      display: none;
                    }

                    .button--container{
                        &.margin{
                            margin-top: 116px;

                            @include for-mobile-devices{
                                margin-top: 20px;

                            }
                        }
                    }


                }
            }

            &.admin__container{
                width: 700px;
                height: auto;
                @include for-mobile-devices{
                    width: 350px;
                    height: auto;
                }


                .form--container{
                    width: 380px;

                    @include for-mobile-devices{
                        width: 350px;
                        height: auto;
                    }

                    height: auto;

                    .button--container{
                        margin-top: 79px;
                        margin-bottom: 40px;

                    }
                }

                .support--container{
                    width: 320px;


                    @include for-mobile-devices{
                        width: 350px;
                        height: 200px;
                    }


                    .button--container{
                        margin-top: 100px;

                        @include for-mobile-devices{
                            margin-top: 20px;
                        }

                        &.margin{
                            margin-top: 155px;
                        }
                    }
                }
            }
        }

    }


    .pink__icon{
        background-image: linear-gradient(90deg, #F87CB2 0%, #EE467B 100%);
        display: table;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        padding-left: 1px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .support--container{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .auth--loader{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.16);
        position: absolute;
        z-index: 1;

        div{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }

    .prepare--text-container{
        position: absolute;
        bottom: -70px;
        width: 355px;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;

        opacity: .3;
        font-family: Roboto, "sans-serif";
        font-weight: 300;
        letter-spacing: 0;
        font-size: 13px;
        color: #000000;
        line-height: 17px;
        text-align: center;

      a{
        cursor: pointer;
        text-decoration: underline;
      }

        @include for-mobile-devices{
          bottom: -60px;
        }

    }

    .warning__text{
      pointer-events: none;
      height: 40px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 8px;
      background: #FFECEC;
      border: 1px solid rgba(255,0,0,.5);
      display: flex;
      align-items: center;
      width: calc(279px - 16px);
      font-family: 'Roboto', sans-serif;
      color: #D81919;
      font-size: 12px;
      font-weight: 100;
      line-height: 15px;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
</style>
